import React from "react"
import { FaEnvelope, FaArrowRight } from "react-icons/fa"

import Layout from "../components/layout.component"
import Seo from "../components/seo.component"

import "./contact.styles.scss"

const ContactPage = ({ location }) => {
  return (
    <Layout className="contact" pathname={location.pathname}>
      <Seo
        title="Unsubscribe"
        description="Unsubscribe from news and updates."
      />
      <section className="hero contact__hero is-medium">
        <div className="container container--text">
          <div className="hero-body is-flex is-flex-direction-column is-align-items-center has-text-centered">
            <h1 className="title is-size-2-mobile is-size-1-tablet">
              Unsubscribe
            </h1>
            <h2 className="subtitle mt-4">
              Unsubscribe from Dan Parasky's news and content updates mailing list.
            </h2>
          </div>
        </div>
      </section>
      <section className="contact__form">
        <div className="container">
          <div className="columns">
            <div className="column is-7">
              <form
                method="post"
                action="/success?type=unsubscribe"
                netlify-honeypot="bot-field"
                data-netlify="true"
                name="Unsubscribe"
                className="contact-form"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="Unsubscribe" />
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <label className="label" htmlFor="email">
                        Email Address
                      </label>
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="email"
                          placeholder="email@example.com"
                          name="email"
                          required
                        />
                        <span className="icon is-small is-left">
                          <FaEnvelope />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <label className="label" htmlFor="comments">
                        Feedback
                      </label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          placeholder="Your feedback is important to me. Would you tell me why you are unsubscribing?"
                          name="comments"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal pt-4">
                  <div className="field-body">
                    <div className="field">
                      <div className="control has-text-left-mobile has-text-right-tablet">
                        <button className="button is-link button--send">Unsubscribe <FaArrowRight /></button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      
    </Layout>
  )
}

export default ContactPage
